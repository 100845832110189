import React from 'react';
import { useTranslation } from 'react-i18next';
import woonbondLogo from './assets/woonbond.png';
import juridischLoketLogo from './assets/juridisch-loket.png';
import huurcommissieLogo from './assets/huurcommissie.png';
import huurteamsLogo from './assets/huurteams.png';
import stichtingWoonLogo from './assets/stichting-woon.png';
import huurbalieLogo from './assets/huurbalie.png';

const resources = [
  {
    name: 'Woonbond',
    logo: woonbondLogo,
    translationKey: 'woonbond'
  },
  {
    name: 'Juridisch Loket',
    logo: juridischLoketLogo,
    translationKey: 'juridischLoket'
  },
  {
    name: 'Huurcommissie',
    logo: huurcommissieLogo,
    translationKey: 'huurcommissie'
  },
  {
    name: 'Huurteams',
    logo: huurteamsLogo,
    translationKey: 'huurteams'
  },
  {
    name: 'Stichting !WOON',
    logo: stichtingWoonLogo,
    translationKey: 'stichtingWoon'
  },
  {
    name: 'Huurbalie Den Haag',
    logo: huurbalieLogo,
    translationKey: 'huurbalie'
  },
];

const NogSteedsVragen = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto py-12 xl:px-20">
      <h1 className="text-3xl font-bold mb-6">{t('nogSteedsVragen.title')}</h1>
      <p className="text-lg mb-4">{t('nogSteedsVragen.intro')}</p>
      <p className="text-lg font-bold mb-6">{t('nogSteedsVragen.email')}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {resources.map((resource) => (
          <div key={resource.name} className="bg-white shadow-lg rounded-lg p-4">
            <img src={resource.logo} alt={resource.name} className="h-16 mx-auto mb-4" />
            <h2 className="text-xl font-bold mb-2">{resource.name}</h2>
            <p className="text-gray-700 mb-4">{t(`nogSteedsVragen.resources.${resource.translationKey}.description`)}</p>
            <a href={t(`nogSteedsVragen.resources.${resource.translationKey}.website`)} target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:text-primary-800">
              {t('nogSteedsVragen.website')}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NogSteedsVragen;
