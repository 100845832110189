import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, NavLink } from 'react-router-dom';
import ChatbotPage from './ChatbotPage';
import ForgetPassword from './ForgetPassword';
import Login from './Login';
import Signup from './Signup';
import Profile from './Profile';
import MoreQuestions from './MoreQuestions';
// import CompanyMission from './CompanyMission';
// import FAQPage from './FAQPage';
// import TeamPage from './TeamPage';
// import ContactPage from './ContactPage';
import Footer from './Footer'
import LandingPage from './LandingPage';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher'
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from './assets/logo-text-only-white-red.svg'
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import { StripeComponent, Return, CheckoutForm } from './Stripe';
import { useUser } from './UserContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function App() {

  const { t } = useTranslation();
  const { user, subscriptionInfo } = useUser();
  const navigation = [
    { name: t('navbar.chat'), href: '/', current: false },
    { name: t('navbar.nogSteedsVragen'), href: '/more-questions', current: false },
    // { name: t('navbar.mission'), href: '/mission', current: false },
    // { name: t('navbar.faq'), href: '/faq', current: false },
    // { name: t('navbar.team'), href: '/team', current: false },
    // { name: t('navbar.contact'), href: '/getintouch', current: false },
  ];

  const userNavigation = user ? [
    { name: t('navbar.profile'), href: '/profile' },
  ] : [
    { name: t('general.login'), href: '/login' },
  ];


  return (
    <Router>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gradient-to-b from-primary-800 to-primary-700 shadow-2xl" >
          {({ open }) => (
            <>
              <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex h-20 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <NavLink to="https://hurai.nl">
                        <div className="flex items-center">
                          <img
                            className="h-8 w-auto mr-2"
                            src={logo}
                            alt="Hurai"
                          />
                        </div>
                      </NavLink>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className={({ isActive }) =>
                              classNames(
                                isActive ? 'bg-primary-600 text-white' : 'text-white hover:bg-primary-700 hover:bg-opacity-75',
                                'rounded-md px-3 py-2 text-sm font-medium'
                              )
                            }
                            end
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">

                      {user && (!subscriptionInfo || !subscriptionInfo.isActive) ? (
                        <NavLink
                          to="/upgrade"
                          className="m-4 px-4 py-2 bg-secondary-700 text-white text-bold rounded-md hover:bg-secondary-600"
                        >
                          Upgrade
                        </NavLink>
                      ) : null}

                      <div className="mr-4 mt-1 z-50">
                        <LanguageSwitcher />
                      </div>


                      {user ? (
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-primary-600 hover:bg-primary-700 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600">
                              <span className="sr-only">Open user menu</span>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" className="w-10 h-10 stroke-foreground hover:stroke-primary-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <Link
                                      to={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      ) : <>
                        <NavLink to="/login" className="flex items-center text-white hover:text-primary-100 mr-4">
                          <p>{t('general.login')}</p>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mt-1 ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                          </svg>
                        </NavLink>
                      </>
                      }



                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-primary-600 p-2 text-primary-200 hover:text-white focus:outline-none">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={classNames(
                        item.current ? 'bg-primary-700 text-white' : 'text-white hover:bg-primary-500 hover:bg-opacity-75',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  <div className="border-t border-gray-700"></div>

                  <div className="mr-4 mt-1 z-50">
                    <LanguageSwitcher />
                  </div>

                  {user && (!subscriptionInfo || !subscriptionInfo.isActive) ? (
                    <Disclosure.Button
                      as={Link}
                      to="/upgrade"
                      className="block w-full text-center px-4 py-2 bg-primary-700 text-white rounded-md hover:bg-primary-800"
                    >
                      Upgrade
                    </Disclosure.Button>
                  ) : null}
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={NavLink}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive ? 'bg-primary-700 text-white' : 'text-white hover:bg-primary-500 hover:bg-opacity-75',
                          'block px-3 py-2 rounded-md text-base font-medium'
                        )
                      }
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="bg-white min-h-[80vh]">
          <div className="p-4">
            <Routes>
              {/* <Route path="/mission" element={<CompanyMission />} /> */}
              {/* <Route path="/faq" element={<FAQPage />} /> */}
              {/* <Route path="/team" element={<TeamPage />} /> */}
              {/* <Route path="/getintouch" element={<ContactPage />} /> */}
              <Route path="/more-questions" element={<MoreQuestions />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="*" element={<Navigate to="/" />} />
              {user ? (
                <>
                  <Route path="/" element={<ChatbotPage />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/upgrade" element={<StripeComponent />} />
                  <Route path="/checkout/:planId" element={<CheckoutForm />} />
                  <Route path="/return" element={<Return />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/signup" element={<Signup />} />
                </>
              )}
            </Routes>
          </div>
        </div>


        <Footer />
      </div>
    </Router>
  );
}

export default App;